import {AureliaReactReportWrapper} from "$pages/reports-react/AureliaReactReportWrapper";
import TemperatureReport from "$pages/reports-react/temperature-report/temperature-report";

class TemperatureReportReactWrapper extends AureliaReactReportWrapper {
  constructor() {
    super(TemperatureReport);
  }
}

export default TemperatureReportReactWrapper
