import React, {FC, HTMLAttributes} from 'react'
import {
  TemperatureReportDocument,
  TemperatureReportDownloadDocument,
  TemperatureReportDownloadQuery,
  TemperatureReportQuery,
  TemperatureReportQueryVariables,
  Reports,
  SortDirection,
} from "$typings/graphql-codegen";
import {useCaseInsensitiveTranslation} from "$lib/hooks/case-insensitive-translation";
import {ColumnDefinition} from "$pages/reports-react/column-definition";
import {displayMeasurement, IMeasurementUnit} from "$lib/unitConverters";
import {ReportGridDataHook} from "$pages/reports-react/generic-report-hooks";
import {SortObject, useGenericReport} from "$pages/reports-react/generic-report";
import {formatDate} from "$lib/dateHelpers";
import {ColumnWidth} from "$pages/reports-react/ColumnWidth";

type ReportResult = TemperatureReportQuery
type ReportVariables = TemperatureReportQueryVariables
type ReportData = TemperatureReportQuery["reports"]["temperature"]["data"][0]
type ReportDownloadResult = TemperatureReportDownloadQuery

enum ColumnKey {
  SiteId = 'siteId',
  Alias = 'alias',
  Buid = 'buid',
  AccessTag = 'accessTag',
  ChannelId = 'channelId',
  SiteChannelId = 'siteChannelId',
  ChannelAlias = 'channelAlias',
  ThisPeriod = 'thisPeriod',
  ThisPeriodFormatted = 'thisPeriodFormatted',
  PreviousPeriod = 'previousPeriod',
  PreviousPeriodFormatted = 'previousPeriodFormatted',
  Deviation = 'deviation',
  DeviationFormatted = 'deviationFormatted',
  Unit = 'unit',
}

interface FormattedReportData extends ReportData {
  [ColumnKey.ThisPeriodFormatted]: string
  [ColumnKey.PreviousPeriodFormatted]: string
  [ColumnKey.DeviationFormatted]: string
}

const TemperatureReport: FC<HTMLAttributes<HTMLElement>> = () => {
  const [t] = useCaseInsensitiveTranslation()
  const Title: string = t('ui_feature_reports_temperature')
  const Filename: string = 'temperature-report'
  
  const DefaultSortSettings: SortObject = {
    sortColumnKey: ColumnKey.Buid,
    sortDirection: SortDirection.Asc
  }
  
  const ColumnDefinitions: ColumnDefinition<FormattedReportData>[] = [
    new ColumnDefinition<FormattedReportData>(
      t('ui_reports_temprature_table_header_sitealias'),
      (row: FormattedReportData) => row[ColumnKey.Alias] ?? '',
      ColumnKey.Alias,
      ColumnWidth.Common.SiteName
    ),
    new ColumnDefinition<FormattedReportData>(
      t('ui_reports_temprature_table_header_channelalias'),
      (row: FormattedReportData) => row[ColumnKey.ChannelAlias] ?? '',
      ColumnKey.ChannelAlias,
      ColumnWidth.Common.ChannelName
    ),
    new ColumnDefinition<FormattedReportData>(
      t('ui_reports_temprature_table_header_buid'),
      (row: FormattedReportData) => row[ColumnKey.Buid] ?? '',
      ColumnKey.Buid,
      ColumnWidth.Common.Buid
    ),
    new ColumnDefinition<FormattedReportData>(
      t('ui_reports_temprature_table_header_accesstag'),
      (row: FormattedReportData) => row[ColumnKey.AccessTag] ?? '',
      ColumnKey.AccessTag,
      ColumnWidth.Common.AccessTag,
    ),
    new ColumnDefinition<FormattedReportData>(
      t('ui_reports_temprature_table_header_this_period'),
      (row: FormattedReportData) => row[ColumnKey.ThisPeriodFormatted] ?? '',
      ColumnKey.ThisPeriod,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<FormattedReportData>(
      t('ui_reports_temprature_table_header_previous_period'),
      (row: FormattedReportData) => row[ColumnKey.PreviousPeriodFormatted] ?? '',
      ColumnKey.PreviousPeriod,
      ColumnWidth.Generic.Short
    ),
    new ColumnDefinition<FormattedReportData>(
      t('ui_reports_temprature_table_header_deviation'),
      (row: FormattedReportData) => row[ColumnKey.DeviationFormatted] ?? '',
      ColumnKey.Deviation,
      ColumnWidth.Generic.Short
    ),
  ]
  
  function formatMeasurement(value: number, unit: IMeasurementUnit): string {
    return displayMeasurement(value, unit, true)
  }
  
  function toFormattedData(data: ReportData): FormattedReportData {
    const _formatted: FormattedReportData = {...data} as FormattedReportData
    const unit: IMeasurementUnit | null = _formatted.unit as IMeasurementUnit | null
    const thisPeriod = _formatted.thisPeriod
    const previousPeriod = _formatted.previousPeriod
    const deviationPeriod = _formatted.deviation

    if (unit) {
      if (thisPeriod) {
        _formatted[ColumnKey.ThisPeriodFormatted] = formatMeasurement(thisPeriod, unit)
      }
      if (previousPeriod) {
        _formatted[ColumnKey.PreviousPeriodFormatted] = formatMeasurement(previousPeriod, unit)
      }
      if (deviationPeriod) {
        const percentUnit: IMeasurementUnit = { ...unit, symbol: '%' }
        _formatted[ColumnKey.DeviationFormatted] = formatMeasurement(deviationPeriod, percentUnit)
      }
    }

    return _formatted
  }
  
  function dataSelector(result: ReportResult): FormattedReportData[] {
    const data: ReportData[] = (result?.reports?.temperature?.data ?? []) as ReportData[]
    return data.map(toFormattedData)
  }
  
  function dataCounter(result: ReportResult): number {
    return dataSelector(result).length
  }
  
  function downloadUrlSelector(result: ReportDownloadResult): string {
    return result.reports.temperature.blobUrl ?? ''
  }
  
  function createTitle(data: ReportGridDataHook<ReportData>, variables?: ReportVariables): JSX.Element {
    const title: JSX.Element = <div>{ Title }</div>
    let subTitle: JSX.Element | null = null
    if (variables?.input) {
      const n: number = data?.data?.length ?? 0
      const CastDate = (v: Date | string | number | null | undefined) => new Date(v ?? '')
      const fromDate: Date = (CastDate)(variables?.input.fromDate)
      const toDate: Date = (CastDate)(variables?.input.toDate)
      const fmt = (date: Date) => formatDate(date, false, '.')
      const from: string = fmt(fromDate)
      const to: string = fmt(toDate)
      subTitle = <div>{ `Period ${from} - ${to} (${n} channels)` }</div>
    }
    const displaySubtitle: boolean | undefined = variables?.input && !data.isLoading
    return (
      <div className="flex flex-col gap-4">
        { title }
        { displaySubtitle && subTitle }
      </div>
    )
  }
  
  const { GenericReport } = useGenericReport<
    ReportResult,
    ReportVariables,
    ReportData,
    ReportDownloadResult
  >()
  
  return <GenericReport
    titleCallback={createTitle}
    reportEnum={Reports.Temperature}
    mainQueryDocument={TemperatureReportDocument}
    downloadQueryDocument={TemperatureReportDownloadDocument}
    columnDefinitions={ColumnDefinitions}
    dataSelector={dataSelector}
    dataCounter={dataCounter}
    defaultSortSettings={DefaultSortSettings}
    downloadUrlSelector={downloadUrlSelector}
    downloadFilename={Filename}
    allowRunningUnfiltered={false} />
}

export default TemperatureReport
